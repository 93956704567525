import { getRegionTranslationId } from '@experiences/constants';
import { UiText } from '@experiences/ui-common';
import { useNavigateWithParams } from '@experiences/util';
import Warning from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApBadge } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useEnabledTenantOperations } from '../../../common/hooks/useEnabledTenantOperations';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import { isAdminSelector } from '../../../store/selectors';
import { UiCard } from '../../common/UiCard';
import UiCardGrid from '../../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';
import { UiTenantStatusBanner } from '../../common/UiTenantStatusBanner';
import { useTenantsContext } from '../TenantsContextProvider';
import useTenantAdminViewModel from './TenantAdminViewModel';

const useStyles = makeStyles(theme => ({
    grid: { maxWidth: '1100px' },
    cardIcon: { width: '40px' },
}));

const TenantAdminHome: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const isAdmin = useSelector(isAdminSelector);
    const { data: tenantContextData } = useTenantsContext();
    const { canEnableTenant } = useEnabledTenantOperations(tenantContextData, isAdmin, false);
    const {
        cardList, tenant,
    } = useTenantAdminViewModel();
    const isOnPrem = useIsOnPrem();

    return (
        <UiPageContainer
            header={tenant && <UiStack
                direction="row"
                align="center"
                gap={SpacingToken.XS}>
                <UiText
                    variant={FontVariantToken.fontSizeLBold}
                    color="var(--color-foreground-emp)"
                    data-cy="ui-page-container-tenant-name"
                    role="heading"
                    aria-level={1}>
                    {tenant.name}
                </UiText>
                {!isOnPrem && <ApBadge
                    data-cy='ui-page-container-tenant-chip'
                    size={'large' as any}
                    label={translate({ id: getRegionTranslationId(tenant.region) })}
                    color={'info' as any} />}
            </UiStack>}
            banner={
                <UiTenantStatusBanner
                    canEnableTenant={canEnableTenant}
                    status={tenant?.status}
                    tenantName={tenant?.name} />
            }>

            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible && <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={
                            <>
                                {typeof card.titleIcon === 'string'
                                    ? <img
                                        className={classes.cardIcon}
                                        src={card.titleIcon}
                                        alt={card.alt} />
                                    : card.titleIcon}
                                { (card.showWarning && card.warningTooltip) ?
                                    <Tooltip
                                        title={card.warningTooltip}>
                                        <Warning
                                            style={{ marginLeft: '10px' }}
                                            color='warning'
                                            data-cy={`${card.dataCy}-warning`} />
                                    </Tooltip> : undefined}
                            </>
                        }
                        description={card.description}
                        onClickLink={() => {
                            let route;
                            if (typeof card.onClickLink === 'string') {
                                route = card.onClickLink;
                            } else {
                                route = card.onClickLink();
                            }
                            navigate(route);
                        }}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        disabled={card.disabled}
                        preview={card.preview}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default TenantAdminHome;
