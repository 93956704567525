import {
    OrganizationSettingTab,
    type OrganizationSettingTabType,
} from '@experiences/interfaces';
import {
    UiCopyButton,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import { FontVariantToken } from '@uipath/apollo-core';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import {
    EmailSettings,
    LoginCustomization,
    OrganizationAdminHome,
    OrganizationSettings,
    OrganizationSettingsAdvanced,
} from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { profile } from '../../store/selectors';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';
import AdminBreadCrumbs from './AdminBreadCrumbs';
import OrganizationSettingsTabs from './subcomponents/OrganizationSettingsTabs';

// ts-unused-exports:disable-next-line
export const OrganizationSettingsPageComponent: React.FC<{ type: OrganizationSettingTabType }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();

    const accountProfile = useSelector(profile);

    const { licenseCode: supportId } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            position="center"
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            primaryActions={
                !process.buildConfigs.hideForMSI && supportId !== 'Unlicensed' && (
                    <UiStack
                        direction="row"
                        align="center"
                        gap={SpacingToken.XS}>
                        <UiText data-cy="organization-settings-primary-supportId">
                            {`${translate({ id: 'CLIENT_SUPPORT_ID' })}:`}
                        </UiText>
                        <UiText
                            variant={FontVariantToken.fontSizeMBold}
                            data-cy="organization-settings-form-supportId">
                            {supportId}
                        </UiText>
                        <UiCopyButton
                            textToCopy={supportId}
                            aria-label={translate({ id: 'CLIENT_COPY_SUPPORT_ID' })}
                            data-cy="organization-settings-form-primary-supportId-copy-button"
                        />
                    </UiStack>
                )
            }>
            <div style={{
                maxWidth: '900px',
                height: '100%',
            }}>
                <OrganizationSettingsTabs type={type} />
            </div>
        </UiPageContainer>
    );
};

export const OrganizationSettingsPageComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: OrganizationAdminHome,
            name: organizationName ?? translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: OrganizationSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    const isEmail = useMatch({
        path: EmailSettings,
        end: false,
    });
    const isLoginCustomization = useMatch(LoginCustomization);
    const isAdvancedSettings = useMatch({
        path: OrganizationSettingsAdvanced,
        end: false,
    });

    const type = useMemo(() => {
        if (isEmail) {
            return OrganizationSettingTab.EMAIL;
        } else if (isLoginCustomization) {
            return OrganizationSettingTab.LOGIN_CUSTOMIZATION;
        } else if (isAdvancedSettings) {
            return OrganizationSettingTab.ADVANCED;
        }
        return OrganizationSettingTab.GENERAL;
    }, [ isEmail, isLoginCustomization, isAdvancedSettings ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <OrganizationSettingsPageComponent type={type} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default OrganizationSettingsPageComponent;
