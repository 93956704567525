import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApButton,
    ApLink,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import { LicensingRobotServicesLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { useEnabledTenantOperations } from '../../../../common/hooks/useEnabledTenantOperations';
import BreadcrumbProvider, { useBreadcrumbs } from '../../../../common/providers/BreadcrumbProvider';
import {
    getTenantById,
    tenantByIdUri,
} from '../../../../services/organization/TenantService';
import { isAdminSelector } from '../../../../store/selectors';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import {
    SpacingToken,
    UiStack,
} from '../../../common/UiStack';
import { UiTenantStatusBanner } from '../../../common/UiTenantStatusBanner';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import UsageChartsComponent from '../../../usage/UsageChartsComponent';
import { TenantStatusConstants } from '../../TenantConstants';
import { useTenantsContext } from '../../TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        breadcrumbText: {
            fontSize: '18px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        chartsContainer: {
            marginTop: '16px',
            maxWidth: '90%',
        },
    }),
);

const ServicesUsagePageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();

    const navigate = useNavigateWithParams();
    const location = useLocation();
    const getRoute = useRouteResolver();

    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'pt', 'pt-BR', 'zh-CN', 'zh-TW' ] });

    const isAdmin = useSelector(isAdminSelector);
    const { tenantId } = useParams() as { tenantId: string };

    const { data: tenantContextData } = useTenantsContext();
    const { canEnableTenant } = useEnabledTenantOperations(tenantContextData, isAdmin, false);

    const {
        data: tenant, isValidating, error,
    } = useSWR(
        isAdmin && tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const goToTenantsPage = useCallback(() => navigate(getRoute(RouteNames.Services)), [ getRoute, navigate ]);

    const { getErrorMessage } = useGetErrorInfo();

    useEffect(() => {
        if (!isAdmin) {
            (async () => {
                await createDialog({
                    title: translate({ id: 'CLIENT_ERROR' }),
                    body: translate({ id: 'CLIENT_FORBIDDEN_ERROR_MESSAGE' }),
                    icon: 'error',
                });

                goToTenantsPage();
            })();
        }
    }, [ isAdmin, createDialog, goToTenantsPage, translate ]);

    useEffect(() => {
        if (!isValidating && error) {
            (async () => setErrorMessage(await getErrorMessage(error)))();
        }
    }, [ error, isValidating, setErrorMessage, getErrorMessage ]);

    const { breadcrumbs } = useBreadcrumbs();

    const usageCharts = useMemo(() => (
        <div className={classes.chartsContainer}>
            <UsageChartsComponent
                tenantIds={[ tenantId ]}
                isServiceMode />
        </div>
    ), [ classes.chartsContainer, tenantId ]);

    return (
        <UiPageContainer
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            banner={<UiTenantStatusBanner
                canEnableTenant={canEnableTenant}
                status={tenant?.status}
                tenantName={tenant?.name} />}
            primaryActions={
                <UiStack
                    direction="row"
                    gap={SpacingToken.XS}
                    align="center">
                    <ApLink
                        href={getLocalizedLink({ articleSlug: LicensingRobotServicesLink })}
                        target="_blank"
                        rel="noreferrer">
                        {translate({ id: 'CLIENT_NEED_MORE_LICENSES' })}
                    </ApLink>
                    <ApButton
                        size="small"
                        onClick={() => navigate(
                            getRoute(
                                RouteNames.TenantServicesUsageAllocation
                                    .replace(':tenantId', tenantId)
                                    .replace(':type', 'configure'),
                            ),
                            { state: { previousLocation: location.pathname } },
                        )}
                        variant="primary"
                        disabled={tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED}
                        data-cy="service-usage-edit-allocation"
                        label={translate({ id: 'CLIENT_EDIT_ALLOCATION' })} />
                </UiStack>
            }
            position="left">
            {usageCharts}
        </UiPageContainer>
    );
};

export const ServicesUsagePageComponentWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const isAdmin = useSelector(isAdminSelector);

    const { tenantId } = useParams() as { tenantId: string };

    const { data: tenant } = useSWR(
        isAdmin && tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.TenantHome.replace(':tenantId', tenantId),
            name: tenant?.name ?? translate({ id: 'CLIENT_HOME' }),
        },
        {
            index: 1,
            link: RouteNames.ServicesUsage.replace(':tenantId', tenantId),
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ], [ tenant?.name, tenantId, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <ServicesUsagePageComponent />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default ServicesUsagePageComponent;
